import React, { useEffect, useState, useRef, useMemo, Suspense } from 'react';
import { styled } from '../stitches.config';

import { PaginatorData } from './types/PaginatorData';

import Button from './atoms/Button';
//import Title from './atoms/Title';
import Loader from '../components/Loader';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { body, h4 } from '../redesign/commonStyles/Fonts';

type PaginatorFetch<T> = (page: number) => Promise<PaginatorData<T>>;

export type PaginatorListType = 'INFINITE_LIST' | 'PAGINATOR' | 'NO_PAGINATOR';

const Title = React.lazy(() => import(/* webpackChunkName: "redesign__atoms__Title" */ './atoms/Title'));

interface IPaginatorList<T> {
    type?: PaginatorListType,
    label?: string;
    onDisplay: (item: T, index: number, length: number, isPlaceholder?: boolean) => React.ReactElement;
    className?: string;
    pageSize?: number;
};

const createPaginatorList = <T extends any>(onFetch: PaginatorFetch<T>, preloadedPosts: T[], itemPlaceholder?: T) => {

    const PaginatorList: React.FC<IPaginatorList<T>> = ({ type = 'PAGINATOR', label, onDisplay, className = '', pageSize = 10 }) => {

        const wrapperRef = useRef<HTMLDivElement>(null);
        const isPreloadedRender = useRef<boolean>(false);

        const [searchParams, setSearchParams] = useSearchParams();
        const paginatorPage = type == 'PAGINATOR' ? (searchParams.get('page') || '0') : '0';

        const [isInit, setInit] = useState<boolean>(preloadedPosts.length > 0);
        const [isLoading, setLoading] = useState<boolean>(preloadedPosts.length <= 0);

        const [pages, setPages] = useState<number>(preloadedPosts.length > pageSize ? 2 : -1);
        const [page, setCurrentPage] = useState<number>(Math.max(parseInt(paginatorPage), 1));
        const [posts, setPosts] = useState<T[]>(preloadedPosts.filter((_, idx) => idx < pageSize) ?? []);

        const placeholderPosts = useMemo(() => Array(isLoading ? pageSize : 0).fill(itemPlaceholder), [isLoading]);

        useEffect(() => {
            if (preloadedPosts.length > 0 && !isPreloadedRender.current) {
                isPreloadedRender.current = true;
                return;
            }
            if (page < 0)
                return;
            if (pages == -1 && paginatorPage && !isInit && type == 'PAGINATOR') {
                setInit(true);
                return goToPage(page, paginatorPage == '0');
            }
            onFetch(page).then(({ posts: newPosts, pages }) => {
                if (type == 'PAGINATOR' || type == 'NO_PAGINATOR') {
                    setPosts(newPosts ?? []);
                } else if (type == 'INFINITE_LIST') {
                    setPosts([...posts, ...newPosts]);
                }
                setPages(pages);
                setLoading(false);
            });
        }, [page, isInit]);

        let paginatorStart = Math.max(1, page - 2);
        if (pages - page <= 2)
            paginatorStart = Math.max(1, pages - 3);
        let paginatorEnd = Math.min(pages, paginatorStart + 4);

        const goToPage = (page: number, preventScroll?: boolean) => {
            setLoading(true);
            setCurrentPage(page);
            if (!preventScroll)
                wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            if (page == 1 || type == 'INFINITE_LIST')
                searchParams.delete('page');
            else
                searchParams.set('page', page.toString())
            setSearchParams(searchParams);
        };

        const displayPostsLength = posts.length + placeholderPosts.length;
        const noPaginator = type == 'NO_PAGINATOR';

        return (
            <Wrapper className={"hp"} ref={wrapperRef}>
                <ArticleSection >
                    {label && (
                        <Suspense fallback={null}>
                            <Title withDots={true} size="large">
                                {label}
                            </Title>
                        </Suspense>
                    )}
                    <div className={`redesign-paginator-list__items ${className}`}>
                        {type == "INFINITE_LIST" ? (
                            <>
                                {posts.map((item, idx) => onDisplay(item, idx, posts.length))}
                                {placeholderPosts.map((item, idx) => onDisplay(item, (posts.length + idx), displayPostsLength, true))}
                            </>
                        ) : (
                            <>
                                {!isLoading && posts.map((item, idx) => onDisplay(item, idx, posts.length))}
                                {placeholderPosts.map((item, idx) => onDisplay(item, (posts.length + idx), displayPostsLength, true))}
                            </>
                        )}
                    </div>
                    {!noPaginator &&
                        <ControlButtons>
                            {type == 'PAGINATOR' && (
                                <>
                                    {page > 1 && <PaginatorButton
                                        {...{
                                            label: '<',
                                            page: page - 1,
                                            onClick: () => goToPage(page - 1)
                                        }}
                                    />}
                                    {Array.from({
                                        length: (paginatorEnd - paginatorStart) + 1
                                    }, (_, i) => ({
                                        label: (paginatorStart + i).toString(),
                                        page: paginatorStart + i,
                                    })).map(
                                        (buttonProps, idx) => (
                                            <PaginatorButton
                                                {...buttonProps}
                                                active={buttonProps.page == page}
                                                onClick={() => goToPage(buttonProps.page)}
                                            />
                                        )
                                    )}
                                    {paginatorEnd < pages && (
                                        <>
                                            <span className={h4()}>...</span>
                                            <PaginatorButton
                                                {...{
                                                    label: pages.toString(),
                                                    page: pages,
                                                    onClick: () => goToPage(pages)
                                                }}
                                            />
                                        </>
                                    )}
                                    {page < pages && <PaginatorButton
                                        {...{
                                            label: '>',
                                            page: page + 1,
                                            onClick: () => goToPage(page + 1)
                                        }}
                                    />}
                                </>
                            )}
                            {type == 'INFINITE_LIST' && page < pages && (
                                <>
                                    <Button
                                        text="Pokaż więcej"
                                        type="primary"
                                        starPosition="none"
                                        onClick={() => goToPage(page + 1, true)}
                                    />
                                </>
                            )}
                        </ControlButtons>
                    }
                </ArticleSection>
            </Wrapper>
        );

    };

    return PaginatorList;
}

export { createPaginatorList };

interface IPaginatorButton {
    label: string;
    page: number;
    active?: boolean;
    onClick: () => void;
};

const PaginatorButton: React.FC<IPaginatorButton> = ({ page, label, active, onClick }) => {

    const { pathname } = useLocation();

    return (
        <a
            href={`${pathname}?page=${page}`}
            onClick={(e) => { e.preventDefault(); onClick(); }}
        >
            <Button
                text={label}
                type={active ? 'primary' : 'secondary'}
                starPosition="none"
            />
        </a>
    );

}

const Wrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

const ArticleSection = styled('div', {
    variants: {
        // isLoading: {
        //     true: {
        //         opacity: 0.1
        //     },
        //     false: {

        //     }
        // }
    }
});

const ControlButtons = styled('div', {
    width: '100%',
    margin: '40px auto',
    gap: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: '$grayscale100'
});

const PageLoader = styled('div', {
    position: 'absolute',
    inset: 0,
    zIndex: 10,
    'img': {
        background: '$grayscale0'
    },
    '& > div': {
        height: '100%'
    }
});

const ShowMore = styled('button', {
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    padding: '12px 20px',
    backgroundColor: '#fff',
    color: '#e7e7e7',
    margin: '20px auto',
    border: '2px solid #272424',
    borderRadius: '6px',
    width: '230px',
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
        content: "",
        display: 'block',
        width: '40px',
        height: '25px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: '-2px',
        right: '-2px'
    },
    '&:after': {
        content: "",
        display: 'block',
        width: '40px',
        height: '25px',
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: '-2px',
        left: '-2px',
    }
});